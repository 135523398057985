import React, { Component } from 'react';
import Course from './course';
import panel from '../../assets/img/header.png';
import mobilePanel from '../../assets/img/mobile-header.png';
import { fetchCourses } from '../firebase';

class Teaching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      courses: [],
    };
  }

  componentDidMount() {
    fetchCourses((courses) => this.setState({ courses }));
    this.setState({ mounted: true });
  }

  handleTime = (time) => {
    const date = time.toDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    let semester = '';
    if (month >= 0 && month <= 5) {
      semester = 'Spring';
    } else if (month >= 8 && month <= 11) {
      semester = 'Fall';
    } else {
      semester = 'Summer';
    }
    return (`${semester}, ${year}`);
  }

  renderCourses = () => {
    return this.state.courses.map((course) => {
      return (
        <Course
          key={course.courseCode}
          courseCode={course.courseCode}
          title={course.title}
          description={course.desc}
          syllabus={course.syllabus}
          time={this.handleTime(course.time)}
        />
      );
    });
  };

  render() {
    if (!this.state.mounted) {
      return (
        <div>
          loading
        </div>
      );
    } else {
      return (
        <div>
          <div className="top-panel">
            <img src={panel} alt="euro-history-imgs" id="panel" />
            <img src={mobilePanel} alt="euro-history-imgs" id="mobile-panel" />
          </div>
          <div className="page-content teaching">
            {this.renderCourses()}
          </div>
        </div>
      );
    }
  }
}

export default Teaching;
