import React from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import Nav from './nav';
import Home from './home';
import Articles from './articles';
import Books from './books';
import Book from './book';
import PopWriting from './popwriting';
import Teaching from './teaching';
import Press from './press';
import Footer from './footer';


import '../style.scss';

const FallBack = (props) => {
  return <div>Sorry! That page does not exist!</div>;
};

const App = (props) => {
  return (
    <Router>
      <Nav />
      <div className="content">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/academic-writing" component={Articles} />
          <Route exact path="/books" component={Books} />
          <Route path="/books/:id" component={Book} />
          <Route exact path="/popular-writing" component={PopWriting} />
          <Route exact path="/teaching" component={Teaching} />
          <Route exact path="/press" component={Press} />

          <Route component={FallBack} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
