import React from 'react';
import panel from '../../assets/img/header.png';
import mobilePanel from '../../assets/img/mobile-header.png';
import sheri from '../../assets/img/home.png';


const Home = () => {
  return (
    <div>
      <div className="top-panel">
        <img src={panel} alt="euro-history-imgs" id="panel" />
        <img src={mobilePanel} alt="euro-history-imgs" id="mobile-panel" />
      </div>

      <div className="page-content home">

        <div className="home-panel">
          <div className="home-img">
            <img src={sheri} alt="sheri" />
          </div>
          <div id="home-text">
            <h1>Professor of Political Science </h1>
            <h2>Barnard College, Columbia University</h2>
            <p>
              My research interests include European history and politics, the development of democracy and dictatorship, populism, fascism and the history of the Left.
            </p>
            <p>
              My writing has appeared in a variety of academic and popular publications, including <i>The New York Times, The Washington Post, Foreign Policy, Foreign Affairs, </i>
              and <i>VOX</i>. My latest book is <i>Democracy and Dictatorship in Europe: From the Ancien Regime to the Present Day.</i>
            </p>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Home;
