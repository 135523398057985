import React, { Component } from 'react';
import panel from '../../assets/img/header.png';
import mobilePanel from '../../assets/img/mobile-header.png';
import {
  fetchBook, fetchSheriReviews, fetchQuotes, fetchInterviews,
} from '../firebase';
import left from '../../assets/icons/left.png';
import right from '../../assets/icons/rightQuote.png';


class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      book: {},
      text: [],
      reviews: [],
      quotes: [],
      interviews: [],
    };
  }


  componentDidMount() {
    const id = this.props.location.pathname.split('/').pop();
    fetchBook(id, (book) => {
      this.setState({ book });
      this.setState({ text: book.desc.split('\\n') });
    });
    fetchSheriReviews(id, (reviews) => this.setState({ reviews }));
    fetchQuotes(id, ((quotes) => this.setState({ quotes })));
    fetchInterviews(id, ((interviews) => this.setState({ interviews })));
    this.setState({ mounted: true });
  }


  renderReviews = () => {
    const size = this.state.reviews.length;
    if (size === 0) {
      return (<div />);
    } else {
      return (
        <div>
          <span className="bold">Reviews: </span> {this.state.reviews.map((review) => {
            let reviewPub = '';
            if (review.order < size) {
              reviewPub = `${review.pub},  `;
            } else {
              reviewPub = review.pub;
            }
            return (
              <a key={review.order} className="book-outlink" target="_blank" rel="noopener noreferrer" href={review.url}> {reviewPub} </a>
            );
          })}
        </div>
      );
    }
  }

  renderInterviews = () => {
    const size = this.state.interviews.length;
    if (size === 0) {
      return (<div />);
    } else {
      return (
        <div>
          <span className="bold">Interviews: </span> {this.state.interviews.map((interview) => {
            let intPub = '';
            if (interview.order < size) {
              intPub = `${interview.pub},  `;
            } else {
              intPub = interview.pub;
            }
            return (
              <a key={interview.order} className="book-outlink" target="_blank" rel="noopener noreferrer" href={interview.url}>{intPub}</a>
            );
          })}
        </div>
      );
    }
  }

  renderQuotes = () => {
    if (this.state.quotes.length === 0) {
      return (<div />);
    } else {
      return (
        <div>
          {this.state.quotes.map((quote) => {
            const quoteAuth = `- ${quote.quoteAuth}, `;
            return (
              <div className="single-quote" key={quote.order}>
                <img src={left} className="open-quote-single" alt="quote" />
                <h5 className="book-quote single quote">
                  {quote.quote}
                </h5>
                <img src={right} className="close-quote-single" alt="quote" />
                <h5 className="book-quote single auth">
                  <span className="authName">{quoteAuth}</span>
                  {quote.quoteAuthBio}
                </h5>
              </div>
            );
          })}
        </div>

      );
    }
  }

  // eslint-disable-next-line consistent-return
  renderText() {
    if (this.state.text) {
      return this.state.text.map((para) => {
        return (
          <p key="">
            {para}
          </p>
        );
      });
    }
  }

  render() {
    if (!this.state.mounted) {
      return (
        <div>
          loading
        </div>
      );
    } else {
      return (
        <div>
          <div className="top-panel">
            <img src={panel} alt="euro-history-imgs" id="panel" />
            <img src={mobilePanel} alt="euro-history-imgs" id="mobile-panel" />
          </div>

          <div className="page-content">
            <h1 className="book-title single-page">{this.state.book.title}</h1>
            <div className="black-line" />

            <div className="top-text">

              <div className="book-text">
                {this.renderText()}
                {this.state.reviews.length === 0 ? (
                  <div className="no-reviews">
                    {this.renderQuotes()}
                  </div>
                ) : (
                  <div>
                    <p>{this.renderReviews()}</p>

                    <p>{this.renderInterviews()}</p>
                  </div>
                )}
              </div>

              <div className="book-img mobile">
                <a href={this.state.book.purchase} target="_blank" rel="noopener noreferrer">
                  <img src={this.state.book.imgurl} className="book-pic" alt="book-img" />
                </a>
                <div className="book-links">
                  <div className="book-buttons">
                    <a href={this.state.book.purchase} target="_blank" rel="noopener noreferrer" className="book-button buy">
                      Buy Now
                    </a>
                    <a href={this.state.book.website} target="_blank" rel="noopener noreferrer" className="book-button site">
                      Website
                    </a>
                    {this.state.book.seminar ? (
                      <a href={this.state.book.seminar} target="_blank" rel="noopener noreferrer" className="book-button seminar">
                        Seminar
                      </a>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>

              </div>

            </div>

            {this.state.reviews.length === 0 ? (
              <div />
            ) : (
              <div>
                {this.renderQuotes()}
              </div>
            )}
          </div>
        </div>

      );
    }
  }
}

export default Book;
