/* eslint-disable guard-for-in */
import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.APIKEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);


const firestore = firebase.firestore();

/** ******************************************************* */
//               CV FUNCTIONS                      //
/** ******************************************************* */

export function fetchCV(callback) {
  firestore.collection('cv')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr[0].url);
    });
}

/** ******************************************************* */
//               POP WRITING FUNCTIONS                      //
/** ******************************************************* */

export function fetchEssays(callback) {
  firestore.collection('essays')
    .orderBy('pubDate', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        console.log(dataWithID);
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

export function fetchReviews(callback) {
  firestore.collection('book_reviews')
    .orderBy('pubDate', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

/** ******************************************************* */
//               ACADEMIC ARTICLES FUNCTIONS                //
/** ******************************************************* */
export function fetchArticles(callback) {
  firestore.collection('academic_articles')
    .orderBy('pubDate', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

export function fetchChapters(callback) {
  firestore.collection('book_chapters')
    .orderBy('pubDate', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

export function fetchAReviews(callback) {
  firestore.collection('academic_book_reviews')
    .orderBy('pubDate', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

/** ******************************************************* */
//               BOOKS FUNCTIONS                          //
/** ******************************************************* */
export function fetchBooks(callback) {
  firestore.collection('books')
    .orderBy('pubDate', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

export function fetchBook(id, callback) {
  firestore.collection('books')
    .doc(id)
    .get()
    .then((querySnapshot) => {
      callback(querySnapshot.data());
    });
}

export function fetchSheriReviews(id, callback) {
  firestore.collection('books')
    .doc(id)
    .collection('reviews')
    .orderBy('order')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

export function fetchQuotes(id, callback) {
  firestore.collection('books')
    .doc(id)
    .collection('quotes')
    .orderBy('order')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}

export function fetchInterviews(id, callback) {
  firestore.collection('books')
    .doc(id)
    .collection('interviews')
    .orderBy('order')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}


/** ******************************************************* */
//               COURSES FUNCTIONS                          //
/** ******************************************************* */

export function fetchCourses(callback) {
  firestore.collection('courses')
    .orderBy('time', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}


/** ******************************************************* */
//               PRESS FUNCTIONS                          //
/** ******************************************************* */

export function fetchPress(callback) {
  firestore.collection('press')
    .orderBy('pubDate', 'desc')
    .get()
    .then((querySnapshot) => {
      const { docs } = querySnapshot;
      const docArr = [];
      for (const index in docs) {
        const dataWithID = { ...docs[index].data(), id: docs[index].id };
        docArr.push(dataWithID);
      }
      callback(docArr);
    });
}
