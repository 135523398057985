import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div id="footer-content">
        <p id="address">
          <span className="bold">Sheri Berman</span> <br />
          Department of Political Science<br />
          Barnard College <br />
          3009 Broadway <br />
          New York, NY 10027
        </p>
        <p>
          Email: <a href="mailto:sberman@barnard.edu" id="email">sberman@barnard.edu</a>
        </p>
      </div>


    </footer>
  );
};

export default Footer;
