import React, { Component } from 'react';
import down from '../../assets/icons/down.png';
import up from '../../assets/icons/up.png';


class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  toggleContent = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }


  render() {
    if (this.state.show) {
      return (
        <div className="course-show">
          <div onClick={this.toggleContent} role="button" tabIndex={0} className="dropdown-title course-title">
            <div>
              <h3> {this.props.title.toUpperCase()} </h3>
              <h4>{this.props.courseCode}</h4>
            </div>
            <div>
              {this.state.show ? (
                <img alt="up" className="up up-courses" src={up} />
              )
                : (
                  <img alt="down" className="down down-courses" src={down} />
                )}
            </div>
          </div>
          <div className="course-content">
            <div className="desc">
              <h5>Description</h5>
              {this.props.description}
            </div>
            <div className="course-info">
              {this.props.syllabus !== undefined ? (
                <div>
                  <a href={this.props.syllabus} target="_blank" rel="noopener noreferrer" className="syllabus">
                    <h5>Syllabus</h5>
                  </a>
                  {this.props.time}
                </div>
              ) : (<div />) }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="course">
          <div onClick={this.toggleContent} role="button" tabIndex={0} className="dropdown-title course-title">
            <div>
              <h3> {this.props.title.toUpperCase()}</h3>
              <h4> {this.props.courseCode}</h4>
            </div>
            <div>
              {this.state.show ? (
                <img alt="up" className="up up-courses" src={up} />
              )
                : (
                  <img alt="down" className="down down-courses" src={down} />
                )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Course;
