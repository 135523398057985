import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import panel from '../../assets/img/header.png';
import mobilePanel from '../../assets/img/mobile-header.png';
import { fetchBooks } from '../firebase';
import arrow from '../../assets/icons/arrow.png';

class Books extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      books: [],
    };
  }


  componentDidMount() {
    fetchBooks((books) => this.setState({ books }));
    this.setState({ mounted: true });
  }

  renderBooks = () => {
    return this.state.books.map((book) => {
      return (
        <div className="single-book" key={book.title}>

          <div className="book-img">
            <a href={book.purchase} target="_blank" rel="noopener noreferrer">

              <img src={book.imgurl} id="home-book" alt="book-img" />
            </a>
          </div>

          <div className="book-content">
            <h3 className="book-title">{book.title}</h3>
            <div className="redLine" />
            <h3 className="mobile-book-title books">{book.title}</h3>

            <div>
              <h5 className="book-quote web">
                {book.displayText}
              </h5>
              <div className="learn books">
                <a href={book.purchase} id="home-buy" target="_blank" rel="noopener noreferrer" className="book-button">
                  Buy
                </a>
                <Link
                  className="book-link"
                  to={{
                    pathname: `/books/${book.id}`,
                    book,
                  }}
                >
                  <h5 className="more">Learn More</h5>
                  <img src={arrow} alt="arrow" className="arrow" />
                </Link>

              </div>

            </div>
            <div className="redLine" />
          </div>

        </div>
      );
    });
  }


  render() {
    if (!this.state.mounted) {
      return (
        <div>
          loading
        </div>
      );
    } else {
      return (
        <div>
          <div className="top-panel">
            <img src={panel} alt="euro-history-imgs" id="panel" />
            <img src={mobilePanel} alt="euro-history-imgs" id="mobile-panel" />
          </div>
          <div className="page-content">
            {this.renderBooks()}

          </div>
        </div>
      );
    }
  }
}

export default Books;
