import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { fetchCV } from '../firebase';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navOverlay: false,
      cvLink: '',
      mounted: false,
    };
  }

  componentDidMount() {
    fetchCV((cvLink) => this.setState({ cvLink }));
    this.setState({ mounted: true });
  }

  toggleNav = () => {
    const x = document.getElementById('mobile-nav');
    if (this.state.navOverlay) {
      x.style.display = 'none';
      this.setState({ navOverlay: false });
    } else {
      x.style.display = 'block';
      this.setState({ navOverlay: true });
    }
  };

  render() {
    if (!this.state.mounted) {
      return (
        <div>
          loading
        </div>
      );
    } else {
      console.log(this.state.cvLink);
      return (
        <nav>

          <div id="logo">
            <NavLink exact to="/" className="navlink mobile" id="name" activeClassName="activeBorder">SHERI BERMAN</NavLink>
          </div>

          <ul id="mobile-nav">
            <li><NavLink to="/popular-writing" onClick={() => this.toggleNav()} className="navlink mobile" activeClassName="activeBorder">POPULAR WRITING</NavLink></li>
            <li><NavLink exact to="/books" onClick={() => this.toggleNav()} className="navlink mobile" activeClassName="activeBorder">BOOKS</NavLink></li>
            <li><NavLink to="/academic-writing" onClick={() => this.toggleNav()} className="navlink mobile" activeClassName="activeBorder">ACADEMIC WRITING</NavLink></li>
            <li><Link to={{ pathname: this.state.cvLink }} target="_blank" rel="noopener noreferrer" className="navlink mobile" activeClassName="activeBorder">CV</Link></li>
            <li><NavLink to="/teaching" onClick={() => this.toggleNav()} className="navlink mobile" activeClassName="activeBorder">TEACHING</NavLink></li>
            <li><NavLink to="/press" onClick={() => this.toggleNav()} className="navlink mobile" activeClassName="activeBorder">PRESS</NavLink></li>
            <li><i tabIndex={0} aria-label="closeNav" id="closeNav" role="button" onClick={() => this.toggleNav()} className="fas fa-times" />        </li>
          </ul>


          <ul className="nav-bar">
            <li>
              <div tabIndex={0} role="button" onClick={() => this.toggleNav()} id="hamburger">
                <div className="line"> </div>
                <div className="line"> </div>
                <div className="line"> </div>
              </div>
            </li>
            {/* <li><NavLink exact to="/" onClick={() => this.toggleNav()} className="navlink" activeClassName="activeBorder">Home</NavLink></li> */}
            <li><NavLink to="/popular-writing" className="navlink" activeClassName="activeBorder">POPULAR WRITING</NavLink></li>
            <li className="nav-dropdown">
              <NavLink exact to="/books" className="navlink" activeClassName="activeBorder">BOOKS</NavLink>
              <div className="dropdown-content">
                <a href="books/UTx3I3SHx3Ony4GErcIv">Democracy and Dictatorship in Europe: From the Ancien Regime to the Present Day</a>
                <a href="books/npxH3XbzRwckKeefqsXX">The Primacy of Politics: Social Democracy and the Making of Europe&#39;s Twentieth Century</a>
                <a href="books/4qytGtktJIYOuvq1Y7IL">The Social Democratic Moment</a>
              </div>
            </li>
            {/* <li><NavLink exact to="/books" className="navlink" activeClassName="activeBorder">BOOKS</NavLink></li> */}
            <li><NavLink to="/academic-writing" className="navlink" activeClassName="activeBorder">ACADEMIC WRITING</NavLink></li>
            <li><Link to={{ pathname: this.state.cvLink }} target="_blank" rel="noopener noreferrer" className="navlink" activeClassName="activeBorder">CV</Link></li>
            <li><NavLink to="/teaching" className="navlink" activeClassName="activeBorder">TEACHING</NavLink></li>
            <li><NavLink to="/press" className="navlink" activeClassName="activeBorder">PRESS</NavLink></li>
          </ul>
        </nav>
      );
    }
  }
}


export default Nav;
