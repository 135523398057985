import React, { Component } from 'react';
import panel from '../../assets/img/header.png';
import mobilePanel from '../../assets/img/mobile-header.png';
import { fetchPress } from '../firebase';
import sheri from '../../assets/img/press.png';


class Press extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      press: [],
    };
  }

  componentDidMount() {
    fetchPress((press) => this.setState({ press }));
    this.setState({ mounted: true });
  }


  render() {
    if (!this.state.mounted) {
      return (
        <div>
          loading
        </div>
      );
    } else {
      return (
        <div>
          <div className="top-panel">
            <img src={panel} alt="euro-history-imgs" id="panel" />
            <img src={mobilePanel} alt="euro-history-imgs" id="mobile-panel" />
          </div>
          <div className="page-content press">
            <div className="left">
              <img src={sheri} alt="sheri" id="press-img" />
            </div>
            <div className="right">
              {this.state.press.map((link) => {
                return (
                  <div className="link-container" key={link.url}>
                    <a className="paper-link" href={link.url} target="_blank" rel="noopener noreferrer">{link.citation}</a>
                  </div>
                );
              })}
            </div>

          </div>
        </div>
      );
    }
  }
}

export default Press;
