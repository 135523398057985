/* eslint-disable camelcase */
import React, { Component } from 'react';
import panel from '../../assets/img/header.png';
import mobilePanel from '../../assets/img/mobile-header.png';
import Papers from './papers';
import { fetchArticles, fetchAReviews, fetchChapters } from '../firebase';

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      articles: [],
      book_chapters: [],
      abook_reviews: [],
    };
  }

  componentDidMount() {
    fetchAReviews((abook_reviews) => this.setState({ abook_reviews }));
    fetchChapters((book_chapters) => this.setState({ book_chapters }));
    fetchArticles((articles) => this.setState({ articles }));
    this.setState({ mounted: true });
  }


  render() {
    if (!this.state.mounted) {
      return (
        <div>
          loading
        </div>
      );
    } else {
      return (
        <div>
          <div className="top-panel">
            <img src={panel} alt="euro-history-imgs" id="panel" />
            <img src={mobilePanel} alt="euro-history-imgs" id="mobile-panel" />
          </div>
          <div className="page-content">
            <Papers
              links={this.state.articles}
              title="Academic Articles"
            />
            <Papers
              links={this.state.book_chapters}
              title="Book Chapters"
            />
            <Papers
              links={this.state.abook_reviews}
              title="Academic Book Reviews "
            />
          </div>
        </div>
      );
    }
  }
}

export default Articles;
