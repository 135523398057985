/* eslint-disable camelcase */
import React, { Component } from 'react';
import panel from '../../assets/img/header.png';
import mobilePanel from '../../assets/img/mobile-header.png';
import Papers from './papers';
import { fetchEssays, fetchReviews } from '../firebase';

class PopWriting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      essays: [],
      book_reviews: [],
    };
  }

  componentDidMount() {
    fetchEssays((essays) => this.setState({ essays }));
    fetchReviews((book_reviews) => this.setState({ book_reviews }));
    this.setState({ mounted: true });
  }


  render() {
    if (!this.state.mounted) {
      return (
        <div>
          loading
        </div>
      );
    } else {
      return (
        <div>
          <div className="top-panel">
            <img src={panel} alt="euro-history-imgs" id="panel" />
            <img src={mobilePanel} alt="euro-history-imgs" id="mobile-panel" />
          </div>
          <div className="page-content">
            <Papers
              links={this.state.essays}
              title="Essays"
            />
            <Papers
              links={this.state.book_reviews}
              title="Book Reviews"
            />
          </div>
        </div>
      );
    }
  }
}

export default PopWriting;
