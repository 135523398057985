import React, { Component } from 'react';
import down from '../../assets/icons/down.png';
import up from '../../assets/icons/up.png';


class Papers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  toggleContent = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }

  renderReprints = (paper) => {
    return (
      paper.reprint ? (
        paper.reprint.map((value, index) => {
          return (
            <div className="reprint">
              <a href={paper.reprint_url[index]} target="_blank" rel="noopener noreferrer" className="paper-link">{value}</a>
            </div>
          );
        })
      ) : (<div />)
    );
  }

  render() {
    if (this.state.show) {
      return (
        <li className="paper-dropdown dropdown dropdown-6 paper-show">
          <div onClick={this.toggleContent} role="button" tabIndex={0} className="dropdown-title paper-title">
            <div>
              <h3> {this.props.title.toUpperCase()}</h3>
            </div>
            <div>
              {this.state.show ? (
                <img alt="up" className="up" src={up} />
              )
                : (
                  <img alt="down" className="down" src={down} />
                )}
            </div>
          </div>
          <ul className="paper-links dropdown_menu dropdown_menu--animated dropdown_menu-6">
            {this.props.links.map((link) => {
              return (
                <li className="link-container" key={link.pubDate}>
                  <a className="paper-link" href={link.url} target="_blank" rel="noopener noreferrer">{link.citation}</a>
                  {this.renderReprints(link)}
                </li>

              );
            })}
          </ul>
        </li>
      );
    } else {
      return (
        <li className="paper-dropdown dropdown dropdown-6">
          <div onClick={this.toggleContent} role="button" tabIndex={0} className="dropdown-title paper-title">
            <div>
              <h3> {this.props.title.toUpperCase()}</h3>
            </div>
            <div>
              <img alt="down" className="down" src={down} />
            </div>
          </div>
        </li>
      );
    }
  }
}

export default Papers;
